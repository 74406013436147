<template>
    <v-app>
        <v-main>
          <div class="preview_overlay" v-if="preview_overlay == 1"></div>
            <v-container fluid class="pa-0" id="app">
                <transition mode="out-in">
                    <router-view></router-view>
                </transition>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
    selected_shop: 'すべて',
    maincolor: '',
    init: [],
    preview_overlay: 0,
  }),
  mounted() {
    // native側から叩けるようグローバル化したい関数を、下記に登録
    window.storageSave = (jsonString) => { this.storageSave(jsonString); },
    window.nativeRedirect = (url) => { this.nativeRedirect(url); }
    // 画面表示時に自動的に処理してほしい関数も、下記に登録
  },
  // 画面表示時実行関数。apiCall結果が返って来てから、その後の処理を行う為、async/awaitを使う
  async created () {
    try {
      this.getUrlParam();

      //現在のpassword_reset_tokenがGETパラメータに含まれていた場合、initを叩かない
      var urlParams = new URLSearchParams(window.location.search);
      var passResetToken = urlParams.get('password_reset_token')

      //現在のurl pathを取得
      var urlpath = location.pathname
      //init APIを叩かないURLを登録
      var noInitURL = ['/sp/info/terms', '/sp/ticket/use_complete', '/sp/ticket/use_error']

      var preview_flg = this.getUrlGetParam('preview')
      var createFlag = this.getUrlGetParam('createFlag'); //未保存が新規プレビューデータ表示・非表示フラグ

      var communeURL = ['/sp/commune/index', '/sp/commune/login', '/sp/commune/register_email']
      var commune_flg = communeURL.includes(urlpath);

      if (passResetToken || noInitURL.includes(urlpath)){
        console.log('no init')
        return false
      } else {
        var init_req = null
        if (commune_flg) {
          // Commune用の画面の場合、initは叩かずenvの設定内容でメインカラーの設定のみ行う。
          this.$set(this, 'maincolor', process.env.VUE_APP_COLOR_CODE);
          const $ = el => document.querySelector(el);
          const maincolorop = this.hex2rgba(this.maincolor, 0.1)
          $(':root').style.setProperty('--main_color', this.maincolor);
          $(':root').style.setProperty('--main_color_op', maincolorop);
          return false
        }else if(preview_flg == 1){
          //プレビュー時は、パラメータを変える
          this.preview_overlay = 1
          init_req = {params: {
            'sid': this.storageGet('sid'),
            'preview': preview_flg,
            'createFlag': createFlag
          }}
            if("/sp/talk/talk".includes(urlpath) && !this.storageGet('sid')){
              var sid = this.getUrlGetParam('sid');
              init_req.params.sid = sid;
            }
        }else{
          init_req = {params: {
            'sid': this.storageGet('sid'),
            'key': this.storageGet('key'),
            'device_uid': this.storageGet('device_uid'),
            'os': this.storageGet('os'),
            'app_version': this.storageGet('app_version'),
            'registration_id': this.storageGet('registration_id')
          }}

          if(this.storageGet('device_uid')){
            init_req.params.device_uid = this.storageGet('device_uid')
          }
        }

        var init_res = await this.apiCall('/user/init', init_req);
        if (!init_res) return false;
        this.$set(this, 'init', init_res);

        // 不要な要素を削除し、初期起動APIの結果をlocalStorageに保存
        delete init_res.available_flgs;
        //delete init_res.menu_data;
        delete init_res.status;
        delete init_res.message;
        delete init_res['Access-Token'];
        delete init_res['Refresh-Token'];
        delete init_res['Settlement-Token'];
        this.storageSave(JSON.stringify(init_res));

        this.$root.init_flg = 1
        this.$root.maincolor = init_res.color_code

        //メインカラーを設定する

        if(this.init.color_code){
          this.$set(this, 'maincolor', this.init.color_code);
          const $ = el => document.querySelector(el);
          const maincolorop = this.hex2rgba(this.init.color_code, 0.1)
          this.$root.maincolorop = maincolorop
          $(':root').style.setProperty('--main_color', this.maincolor);
          $(':root').style.setProperty('--main_color_op', maincolorop);
        } else {
          this.$set(this, 'maincolor', '#FF00B1');
          const $ = el => document.querySelector(el);
          this.$root.maincolorop = 'rgba(255,0,177,0.1)'
          $(':root').style.setProperty('--main_color', '#FF00B1');
          $(':root').style.setProperty('--main_color_op', 'rgba(255,0,177,0.1)');
        }

      }
    } catch(e) {
        this.loading = false
        this.callDialog('接続エラー', '一時的なエラーです。ネットワークの状態が不安定です。再度お試しください', 3);
    }
  },
  methods: {
    /**
     * hexカラーコード定義をrgbaに変換する.
    */
    hex2rgba (hex, alpha = 1) {
        // ロングバージョンの場合（例：#FF0000）
        let r = hex.match(/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i)
        let c = null
        if (r) {
            c = r.slice(1,4).map(function(x) { return parseInt(x, 16) })
        }
        // ショートバージョンの場合（例：#F00）
        r = hex.match(/^#([0-9a-f])([0-9a-f])([0-9a-f])$/i)
        if (r) {
            c = r.slice(1,4).map(function(x) { return 0x11 * parseInt(x, 16) })
        }
        // 該当しない場合は、nullを返す.
        if (!c) {
            return null
        }
        return `rgba(${c[0]}, ${c[1]}, ${c[2]}, ${alpha})`
    }
  }
};
</script>


<style>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;700&display=swap');
/*
:root {
  --main_color: #FF00B1;
  --main_color_op: rgba(255,0,177,0.1);
}
*/
body {
  color: #333;
  font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", "YuGothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", "Meiryo", "verdana", sans-serif;
  overscroll-behavior: none;
  background-color: #FAFAFA;
}

fieldset {
  background-color: #FFFFFF;
}

.v-textarea {
  background-color: #FFFFFF;
}

.maincolor {
  color: var(--main_color) !important;
}

.maincolor-bg {
  background-color: var(--main_color) !important;
  color: #fff !important;
}

.maincolor-bg_opacity {
  background-color: var(--main_color_op) !important;
}

.maincolor-btn2 {
    border: solid 2px var(--main_color) !important;
    color: var(--main_color) !important;
    background-color: #fff !important;
}

.logo {
    min-height: 50px;
    position: sticky;
    z-index: 25;
    background-color: #FFFFFF;
    box-shadow: 0 3px 10px #00000029;
    box-sizing: border-box;
    padding: 0;
    width: 100vw;
    top: 0;
    align-items: flex-start;
}

.svg_maincolor path {
  fill: var(--main_color);
}
.svg_maincolor circle {
  stroke: var(--main_color);
}
.svg_maincolor rect {
  stroke: var(--main_color);
}

.svg_maincolor_circle_fill circle {
  fill: var(--main_color);
}

.svg_maincolor_rect_fill rect {
  fill: var(--main_color);
}

.border_maincolor {
  border-color: var(--main_color) !important;
}

.nl2br {
    white-space: pre-wrap;
    word-wrap: break-word;
}

.default_button {
  font-weight: bold !important;
  width: 60%;
  height: 46px !important;
}

.default_button2 {
  font-weight: bold !important;
  height: 46px !important;
  width: 80%;
}

.default_button3 {
  background-color: var(--main_color) !important;
  color: #fff !important;
  width: 80%;
  font-size: 0.95rem !important;
  height: 2.4rem !important;
  border: solid 1px var(--main_color) !important;
  max-width: 14rem;
  min-width: initial !important;
  margin: auto;
  font-weight: initial !important;
  opacity: 1;
  letter-spacing: 0rem !important;
}

.default_button4 {
  border: solid 2px var(--main_color) !important;
  color: var(--main_color) !important;
  background-color: #fff !important;
  width: 80%;
  font-size: 0.95rem !important;
  height: 2.4rem !important;
  border: solid 1px var(--main_color) !important;
  max-width: 14rem;
  min-width: initial !important;
  margin: auto;
  font-weight: initial !important;
  opacity: 1;
  letter-spacing: 0rem !important;
}

.default_button5 {
  font-weight: bold !important;
  font-size: 0.6rem !important;
  height: 32px !important;
  width: 80%;
}

.default_button6 {
  font-weight: bold !important;
  font-size: 0.6rem !important;
  height: 32px !important;
  width: 40%;
}

.btn_small {
  height: 32px !important;
}

.graybtn {
  background-color: #EEEEEE !important;
  color: #787878 !important;
  font-weight: bold !important;
  font-size: 12px !important;
  height: 40px !important;
}

.fontsize9 {
  font-size: 9px !important;
}
.fontsize11 {
  font-size: 11px !important;
}
.fontsize12 {
  font-size: 12px !important;
}
.fontsize13 {
  font-size: 13px !important;
}
.fontsize14 {
  font-size: 14px !important;
}
.fontsize15 {
  font-size: 15px !important;
}
.fontsize16 {
  font-size: 16px !important;
}
.fontsize18 {
  font-size: 18px !important;
}

.no_overflow {
  overflow-y: visible !important;
}
.no_radius {
  border-radius: 0 !important;
}

/* width css */
.w60 {
  width: 60% !important;
}
.w70 {
  width: 70% !important;
}
.w80 {
  width: 80% !important;
}
.w90 {
  width: 90% !important;
}
.w100 {
  width: 100% !important;
}

/* NEW Mark */
.newribbon {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 48px 48px 0 0;
    border-color: #FF5256 transparent transparent transparent;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
}
.newribbon span {
    font-size: 11px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: 0.2em;
    color: #fff;
    position: absolute;
    top: -37px;
    left: 1px;
    z-index: 25;
    transform: rotate(-45deg) scale(0.8);
}

.preview_overlay {
  width: 100vw;
  height: 100%;
  position: fixed;
  top:0;
  left:0;
  z-index: 1000000;
}

/* 画面遷移アニメーション */

.v-enter {
    transform: translate(-100px, 0);
    opacity: 0;
}

.v-enter-to {
    opacity: 1;
}

.v-enter-active {
    transition: all 1s 0s ease;
}

.v-leave {
    transform: translate(0, 0);
    opacity: 1;
}

.v-leave-to {
    transform: translate(100px, 0);
    opacity: 0;
}

.v-leave-active {
    transition: all .5s 0s ease;
}

/* vuetify共通オーバーライド */
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 15px !important;
}
.theme--light.v-application {
  background: none !important;
}

.ql-align-center {
  text-align: center !important;
}
.ql-align-left {
  text-align: left !important;
}
.ql-align-right {
  text-align: right !important;
}

.ql-size-small {
  font-size: 14px;
}
.ql-size-large {
  font-size: 18px;
}
.ql-size-huge {
  font-size: 32px;
}
</style>
