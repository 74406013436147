import Vue from 'vue'
import commonDialog from '@/components/commonDialog.vue'

export default {
  /* 本来は、ここに共通初期化処理として、created()関数を設けようとしたが、
   * グローバルミックスインされたcreatedは、Vueインスタンスの数だけ関数が走る為、
   * getUrlParamはcreatedに書かず、App.vueのmounted時に呼び出すようにしました。
   */

  // 共通関数宣言場所
  methods: {
    /**
     * URLパラメータを取得し、localStorageに保存する共通関数(重複keyは後の値で上書きになる)
     * （注：App.vueのmountedにて、画面表示時、自動呼出処理化しています）
     */
    getUrlParam() {
      var queryString = location.search;
      if (queryString) {
        var urlParam = [...new URLSearchParams(queryString).entries()].reduce((obj, e) => ({ ...obj, [e[0]]: e[1] }), {});
        this.storageSave(JSON.stringify(urlParam));
      }
    },

    /**
     * URLパラメータの値を取得する
     * （注：App.vueのmountedにて、画面表示時、自動呼出処理化しています）
     */
    getUrlGetParam(key) {
      var queryString = location.search;
      if (queryString) {
        var urlParam = [...new URLSearchParams(queryString).entries()].reduce((obj, e) => ({ ...obj, [e[0]]: e[1] }), {});
          return urlParam[key]
      }
    },

    /**
     * user/initを呼んで、バッジの状態をNativeに送る
     */
    async callUserInitAndSendBadgeConditionToNative() {
      //
      const init_req = {params: {
          'sid': this.storageGet('sid'),
          'key': this.storageGet('key'),
          'device_uid': this.storageGet('device_uid'),
          'os': this.storageGet('os'),
          'app_version': this.storageGet('app_version'),
          'registration_id': this.storageGet('registration_id')
        }}
      const init_res = await this.apiCall('/user/init', init_req);
      if (!init_res) return false;

      const badgeParams = {
        '_vue_param': true,
        'menu_data': init_res.menu_data,
        'total_unread_count': init_res.total_unread_count,
      }
      this.nativePost(badgeParams)
    },

    /**
     * ローカルストレージに保存されいているバッジの状態をNativeに送る
     */
    sendBadgeConditionToNative(rank_img_path='') {
      const init = this.storageGet('*')
      const badgeParams = {
        '_vue_param': true,
        'menu_data': init.menu_data,
        'total_unread_count': init.total_unread_count,
        'rank_img_path': rank_img_path,
      }
      this.nativePost(badgeParams)
    },

    /**
     * ローカルストレージに保存されいているユーザ情報をNativeに送る
     */
    requestUpdateUserToNative() {
      const init = this.storageGet('*')
      const params = {
        '_vue_param': true,
        '_action_': 'update_user',
        'user_name': init.nickname, // nativeにはニックネームを返す
        'device_uid': init.device_uid,
      }
      this.nativePost(params)
    },

    /**
     * ネイティブ側へ値や信号を送る関数。引数ObjectをJSONstringに変換して送り出す。
     * （注：ネイティブ側から叩けるようにする為には、App.vueのmountedにグローバル化する登録が必要）
     * @param {object} obj オブジェクト形式の送信値。
     */
    nativePost(obj) {
      /*
       * 以下ネイティブ側との設定用key名とvalue値の仕様（ネイティブ側の動作を指定できる）
       * obj内に「_vue_param: "true"」設定すると → アラートを出さず、特定のキーで遷移処理などをしてくれる。
       * obj内に「_vue_param: "true" _alert: "テスト"」設定 → 「テスト」文字の埋め込まれた、ネイティブ側のアラートを出してくれる。
       * obj内に、特定key名の設定無し → alert関数動作のまま、webviewアラートをそのまま出してくれる。
       */
      var previewflg = this.getUrlGetParam('preview');

      if(previewflg != 1){
        var jsonString = JSON.stringify(obj);
        console.log("alert ---> " + jsonString);
        alert(jsonString);
      }
    },

    /**
    * 【試験実装】ネイティブ側からVue側へ、遷移前ページを履歴に残さず、urlリダイレクト命令を出す関数
    * （注：ネイティブ側から叩けるようにする為には、App.vueのmountedにグローバル化する登録が必要）
    * @param {string} url リダイレクト先URLの指定。
    */
    nativeRedirect(url) {
      if (url) {
        location.replace(url);
        return true;
      }
      return false;
    },

    /**
     * 引数に設定されたデータを、重複したキー名のみ上書きしつつ、localStorageに保存する関数。
     * @param {string} jsonString jsonString形式の保存したい値。
     */
    storageSave(jsonString) {
      try {
        if (localStorage.storageData) {
          // 既にstorageDataがある場合は、旧データと新データをObjectに変換・マージしたJsonStringを生成。
          var oldObjData = JSON.parse(localStorage.storageData);
          var newObjData = JSON.parse(jsonString);
          jsonString = JSON.stringify(Object.assign(oldObjData, newObjData)); //キー名重複は上書き
        }

        // ローカルストレージはobjを保存できないので、jsonStringのまま保存。
        localStorage.storageData = jsonString;
        //console.log("Save Storage:" + localStorage.storageData);
        return true
      } catch {
        //console.log("Error Save Storage:" + jsonString);
        return false
      }
    },

    /**
     * 保存したlocalStorageのデータをobjに変換して、特定key値を取得する関数。
     * @param {string} key 取得するkey値の指定。'*'指定で全データをobject形式で取得。
     */
    storageGet(key) {
      if (localStorage.storageData) {
        var obj = JSON.parse(localStorage.storageData);
        if (key == '*') {
          // 引数keyに*文字を使うと、obj自体を全て取得
          //console.log(obj);
          return obj ? obj : false;
        } else {
          // それ以外は指定キーのvalueを取得
          //console.log(obj[key]);
          return obj[key] ? obj[key] : false;
        }
      }
      return false;
    },

    /**
     * 保存したlocalStorageのデータから、特定key値を削除する関数。（削除成功時return true）
     * @param {string} key 削除するkey値の指定。'*'指定でlocalStorage自体を削除(全削除)。
     */
    storageDelete(key) {
      if (localStorage.storageData) {
        var obj = JSON.parse(localStorage.storageData);
        if (key == '*') {
          // localStorage自体を削除
          localStorage.removeItem('storageData');
          //console.log('delete storage !!');
          return localStorage.storageData ? false : true;
        } else {
          // それ以外は指定キーのvalueを削除し、localStorageを更新。
          delete obj[key];
          localStorage.storageData = JSON.stringify(obj);
          //console.log(obj[key]);
          //console.log(key + 'delete storage !!');
          return obj[key] ? false : true;
        }
      }
      return false;
    },

    storageSessionSave(jsonString){
      try {
        if (sessionStorage.storageData) {
          // 既にstorageDataがある場合は、旧データと新データをObjectに変換・マージしたJsonStringを生成。
          var oldObjData = JSON.parse(sessionStorage.storageData);
          var newObjData = JSON.parse(jsonString);
          jsonString = JSON.stringify(Object.assign(oldObjData, newObjData)); //キー名重複は上書き
        }
        // ローカルストレージはobjを保存できないので、jsonStringのまま保存。
        sessionStorage.storageData = jsonString;
        return true
      } catch {
        return false
      }
    },
    storageSessionGet(key) {
      if (sessionStorage.storageData) {
        var obj = JSON.parse(sessionStorage.storageData);
        if (key == '*') {
          // 引数keyに*文字を使うと、obj自体を全て取得
          return obj ? obj : false;
        } else {
          // それ以外は指定キーのvalueを取得
          return obj[key] ? obj[key] : false;
        }
      }
      return false;
    },
    storageSessionDelete(key) {
      if (sessionStorage.storageData) {
        var obj = JSON.parse(sessionStorage.storageData);
        if (key == '*') {
          // localStorage自体を削除
          sessionStorage.removeItem('storageData');
          return sessionStorage.storageData ? false : true;
        } else {
          // それ以外は指定キーのvalueを削除し、localStorageを更新。
          delete obj[key];
          sessionStorage.storageData = JSON.stringify(obj);
          return obj[key] ? false : true;
        }
      }
      return false;
    },

    /**
     *
     * @param {*} url
     * @param {*} request
     * @param {*} type
     * @param {*} host
     * @param {*} error_title
     * @returns
     */
    apiCallCustomerPF(path, request) {
      const access_token = this.storageGet('access_token')
      const refresh_token = this.storageGet('refresh_token')

      const hostUrl = process.env.VUE_APP_API_URL_CUSTOMER_PF; //共通顧客管理基盤API
      const axiosBase = require('axios');
      const axios = axiosBase.create({
        baseURL: hostUrl,
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS',
          'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Origin, Content-Type, Accept, Referer, Content-Length, X-Requested-With, Access-Token, Refresh-Token, Settlement-Token',
          'Access-Control-Allow-Credentials': true,
          'Authorization': 'Bearer ' + access_token,
          'Refresh-Token': refresh_token,   // TODO fujiwara PFにリフレッシュトークンの仕組みができたらそれに合わせる
        },
        responseType: 'json',
        timeout : 100000,
      });

      var res_data = axios['post'](path, request).then(response => {
        return response.data;
      }).catch(err => {
        throw err.response.data.error;
      });
      return res_data;
    },

    /**
     * APIでトークン発行後、ページ表示します。
     * @param path
     * @param params
     */
    showEcPage(path, params) {
      const baseUrl = process.env.VUE_APP_EC_BASE_URL;
      const axios = require('axios').create({
        baseURL: baseUrl,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS',
          'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Origin, Content-Type, Accept, Referer, Content-Length, X-Requested-With, Access-Token, Refresh-Token, Settlement-Token',
          'Access-Control-Allow-Credentials': true,
        },
        responseType: 'json',
        timeout : 100000,
      });

      const tokenApiPath = '/api/token_get.php'
      const tokenApiParams = {
        api_type : 10,
        api_id   : process.env.VUE_APP_EC_API_ID,
        api_key  : process.env.VUE_APP_EC_API_KEY,
      }

      axios['post'](tokenApiPath, tokenApiParams).then(response => {
        if (!response.data.api_token) {
          throw response.data.error_message;
        }
        window.location.href = baseUrl + path + '?api_type=10&api_token=' + response.data.api_token + '&' + params
      }).catch(err => {
        if (!err.response) {
          throw err
        }
        throw err.response.data.error;
      });
    },

    // TODO test
    callPfApiFromEcMock(path, request) {
      const pf_token_for_ec = this.storageGet('pf_token_for_ec')

      const hostUrl = process.env.VUE_APP_API_URL_CUSTOMER_PF; //共通顧客管理基盤API
      // const axiosBase = require('axios');
      const axios = require('axios').create({
        baseURL: hostUrl,
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS',
          'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Origin, Content-Type, Accept, Referer, Content-Length, X-Requested-With, Access-Token, Refresh-Token, Settlement-Token',
          'Access-Control-Allow-Credentials': true,
          'Authorization': 'Bearer ' + pf_token_for_ec,
        },
        responseType: 'json',
        timeout : 100000,
      });

      const url = hostUrl + path;

      console.log("--- callPfApiByMock --- " + url);
      console.log(request);
      console.log('pf_token_for_ec :' + pf_token_for_ec);

      var res_data = axios['post'](path, request).then(response => {
        console.log("--- success --- " + response + "\n" + url);
        console.log(response.data);
        return response.data;
      }).catch(err => {
        console.log("--- error ---" + url);
        console.log(err.response);
        throw err.response.data.error;
      });
      return res_data;
    },

    /**
     * apiCall()の派生です。パラメータ詳細はapiCall()を参照ください。
     *
     * 既存のapiCall()は、ユーザ認証エラー(401,701)が発生した場合に、
     * Nativeにログインページ表示要求を出すところまでセットになっています。
     * 処理の都合上、ログインページ表示要求を出したくない場合、本メソッドを利用します。
     *
     * 本メソッドはユーザ認証されていない場合、
     * ログインページ表示要求を出さずに401か701を返します。
     * ログイン認証されている場合は、呼出し元でハンドリングしてください。
     *
     * @return 成功時:APIのレスポンス。失敗時:false、または401,701。
     */
    apiCallIfUnauthorizedReturnsErrorCode(path, request, type = 'get', host = 0, error_title = 'エラー') {
      return this.apiCall(path, request, type, host, error_title, true);
    },

    /**
     * APIを叩く関数。
     * @param {string} url baseURLの続きに当たる、相対パスURL。
     * @param {object} request オブジェクト形式のリクエストパラメーター。
     * @param {string} type get/post/delete、APIのメソッドタイプを文字列で指定（axiosに左記以外の種類があるかは不明）
     * @param {number} host hostUrlに事前登録しておいた別ホストへ、APIの叩き先を指定（事前登録すれば、複数URL先登録可）
     * @param {boolean} returnsUnauthorizedErrorCode 401, 701エラー時の振る舞い。false:従来動作。apiCallメソッド内でNativeにログイン画面表示を要求。true:401 or 701を返す。ログイン画面遷移しない。
     */
    apiCall(path, request, type = 'get', host = 0, error_title = 'エラー', returnsUnauthorizedErrorCode = false) {
      const access_token = this.storageGet('access_token')
      // 管理ツールからのプレビューで以下ないと言われてこける。やまやでは不要。
      // const refresh_token = this.storageGet('refresh_token')
      // const settlement_token = this.storageGet('settlement_token')

      const hostUrl = {
        0: process.env.VUE_APP_API_URL_BASE, //開発フロント側API(デフォルト先)
        1: process.env.VUE_APP_API_URL_STUB, //スタブAPI
        2: process.env.VUE_APP_API_URL_AUTH, //認証サーバ
        3: process.env.VUE_APP_API_URL_PAYMENT, //決済サーバ
      }

      const axiosBase = require('axios');

      //本番では以下BASIC認証については不要となります
      /*
      const clientId = "uplink";
      const clientSecret = "usen-cc";
      //Basic認証のために、base64でエンコードする
      const encodedData = Buffer.from(clientId + ":" + clientSecret).toString(
        "base64"
      );
      */
      const axios = axiosBase.create({
        baseURL: hostUrl[host],
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS',
          'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Origin, Content-Type, Accept, Referer, Content-Length, X-Requested-With, Access-Token, Refresh-Token, Settlement-Token',
          'Access-Control-Allow-Credentials': true,
          'Access-Token': access_token,
          // 'Refresh-Token': refresh_token,
          // 'Settlement-Token': settlement_token,
          //'Authorization': "Basic " + encodedData,
        },
        responseType: 'json',
        timeout : 100000,
      });

      // 必須パラメーターセット
      if (host == 0) {
        if (type == 'get') {
          request.params = this.setApiIndispensableParams(request.params);
        } else {
          request = this.setApiIndispensableParams(request);
        }
      }

      const url = hostUrl[host] + path;
      console.log("--- apiCall --- " + url);
      console.log(request);
      console.log('access_token :' + access_token);
      // console.log('refresh_token :' + refresh_token);
      // console.log('settlement_token :' + settlement_token);
      console.log('returnsUnauthorizedErrorCode :' + returnsUnauthorizedErrorCode);

      var res_data = axios[type](path, request).then(response => {
        console.log('--- success ---' + url);
        console.log(response);
        // トークンの更新があれば保存
        this.tokenUpdate(response);
        // API通信そのものの成功時の処理。
        switch (response.data.status) {
          // status異常値の場合
          case 101:
          case 102:
          case 103:
          case 104:
          case 201:
          case 202:
          case 402:
          case 500:
          case 501:
          case 901:
          case 999:
              this.callDialog(error_title, response.data.message, 1);
              return false;
          case 404:
              this.callDialog(error_title, 'API システムエラー: ['+response.data.status+'] ['+host+']', 1);
              return false;
          case 401: // 401 Unauthorized tokenが切れた場合
          case 701: // 701ログインエラーの場合、ネイティブにアラートを送りログイン画面に遷移させる
              if (returnsUnauthorizedErrorCode) {
                  return response.data.status;
              } else {
                this.requestToNativeToShowLogin();
              }
              break;
          case 703:
              this.callDialog(error_title, response.data.message + "。時間をおいて再試行をお願いいたします。", 1);
              this.loading = false;
              return false;
          default:
        }

        return response.data;
      }).catch(err => {
        console.log('--- error --- ' + url);
        console.log(err);
        console.log(err.response);

        error_title = error_title == 'エラー' ? '通信エラー' : error_title;
        // API通信そのもののエラー時の処理。
        switch (err.response.status) {
          case 401:
          case 701: //401,701ネイティブにアラートを送りログイン画面に遷移させる
            if (returnsUnauthorizedErrorCode) {
              return err.response.status;
            } else {
              this.requestToNativeToShowLogin();
            }
            break;
          case 404:
          case 500:
            this.callDialog(error_title, 'API システムエラー: ['+err.response.status+'] ['+host+']', 1);
            break;
          default:
            this.callDialog(error_title, '一時的なエラーです。ネットワークの状態が不安定です。再度お試しください['+err.response.status+']', 1);
            break;
        }
        return false;
      });

      return res_data;
    },

    /**
     * 共通ダイアログを表示する共通関数
     * @param {string} title ダイアログのタイトル文字
     * @param {string} message ダイアログの本文文字
     * @param {number} type ダイアログの表示タイプを指定。1は閉じるボタン。2は再アクセスボタン(APIエラーである事を考慮した)
     *
     */
    //20210121 NEW_DEV-768 cyber 李 start
    callDialog(title, message, type, id = null, history_id = null) {
      // 共通ダイアログ読み込み、表示
      var CD = Vue.extend(commonDialog);
      new CD ({
        parent: this,
        propsData: {
          title: title,
          message: message,
          type: type,
          id: id,
          history_id: history_id,
          //20210121 NEW_DEV-768 cyber 李 end
        },
      });
    },

    /**
     * routerlinkで遷移させる共通関数
     * @param {string} url 遷移先urlの指定（指定しないと現在の画面をリロードになる）
     */
    toLink(url = this.$router.currentRoute.path) {
      location.href = url;
      // this.$router.push(url);での遷移の場合、urlを絶対パスで指定できない為。
      // (URL末尾に指定パスがくっつき重複する。上記なら、絶対パスでも相対パスでも遷移可能)
    },

    /**
     * routerlinkで遷移させる共通関数
     * @param {string} url 遷移先urlの指定（指定しないと現在の画面をリロードになる）
     */
    routerBindTo(param) {
      this.$router.push(param);
    },

    /**
     * 戻るボタン操作共通関数
     * 戻り先がない場合はトップへ移動
     * @param {string} routeName
     * @param {object} params
     * @param {bool} back
    */
    routerBack(routeName = null, params = null, back = true) {
      // パス指定があっても戻り先が登録されていれば優先する
      if (window.history.length > 1 && back) {
        this.$router.go(-1);
      } else if (routeName || params) {
        let routeParam = {name: routeName};
        if (params) {
          routeParam.query = params;
        }
        this.routerBindTo(routeParam);
      } else {
        // トップへ
        let init = this.storageGet('*');
        if (init && init.menu_data && init.menu_data.menus) {
          let topName = init.menu_data.menus[0].screen_id.replace('_', '');
          this.$router.push({'name': topName});
        }
      }
    },

    /**
     * URL文字列のドメインまで取得する関数
     * @param
     */
    getDomain() {
      var href = location.href;
      return href.match('http[s]?://[^/]+/');
    },

    /**
     * URL文字列のパス部分を取得する関数
     * @param {string} url 遷移先url
     */
    getPath(url) {
      var domain = url.match('http[s]?://[^/]+');
      return url.replace(domain, '');
    },

    /**
     * routerlinkでリロードさせる関数
     */
    reload() {
      this.$router.go({path: this.$router.currentRoute.path, force: true});
    },

    /**
     * 遷移処理をまとめた共通関数。（遷移失敗時はreturn false)
     * （下記reqは、ルーティングAPIのレスポンス値を使用しても良いし、下記4種のキー名で遷移情報を捏造したobjectデータも使用可能）
     * @param {object} req 遷移に必要な情報(feature_id, screen_id, transition_type, destination)を含むobject
     */
    screenTransition(req) {

      console.log(req);

      // ページタイトルがない場合は、メニュー情報から取得する
      if (!req.page_title && req.feature_id) {
          var menu = this.storageGet('menu_data')
          menu = menu['all_menus']
          menu.forEach( item => {
            if (item.feature_id == req.feature_id) {
              req.page_title = item.title
            }
          });
      }

      //console.log('req', req)
      // 分かりにくいのでAndroidのソースから抽出した
      // TRANSITION_TYPE_VUE = 1
      // TRANSITION_TYPE_WEBVIEW = 2
      // TRANSITION_TYPE_ACTIVITY = 3
      // TRANSITION_TYPE_EXTERNAL_VIEW = 4
      // TRANSITION_TYPE_APP = 5

      switch (req.transition_type) {
        case 1:
          if (req.destination) {
            /*
            const str = 'https://test.com/top';
            const words = str.split('/');
            console.log(words[words.length-1]);
            */
            this.toLink(req.destination);
          } else {
            return false;
          }
          break;
        case 2:
          // nativePostへ信号、key['_vue_param']設定し、ネイティブ遷移の動作をするようネイティブ側に指示する。
          req['_vue_param'] = true;
          this.nativePost(req);
          break;
        case 3:
        case 4:
          // nativePostへ信号、key['_vue_param']設定し、外部URLを表示するようにする。
          req['_vue_param'] = true;
          this.nativePost(req);
          break;
        case 5:
          // 20201105 外部リンク追加対応 cyber 段 start
          // nativePostへ信号、key['_vue_param']設定し、外部URLを表示するようにする。
          req['_vue_param'] = true;
          this.nativePost(req);
          break;
          // 20201105 外部リンク追加対応 cyber 段 end
        default:
          return false;
      }
      return true;
    },
    /**
     * API実行に必須なパラメーターがなければセット
     * @param obj params
     * @return obj params
     */
    setApiIndispensableParams(params) {
      let tmp = ['sid', 'key', 'device_uid', 'device_id', 'os'];
      let info = this.storageGet('*');

      for (let key in info) {
        if (!params[key] && tmp.indexOf(key) !== -1) {
          params[key] = info[key];
        }
      }

      //プレビューパラメータがあった場合
      if(this.$route.query.preview == 1 && location.hostname == process.env.VUE_APP_HOST_NAME){
        params['preview'] = 1
      }
      return params;
    },
    /**
     * スクロール位置トップへ移動
     * @return void
     */
    scrollMoveTop() {
      if (window.scrollY > 0) {
        Vue.prototype.$scrollToTop = window.scrollTo(0,0);
      }
    },
    /**
     * トークン更新
     * @param  {[type]} res
     * @return {[type]}
     */
    tokenUpdate(res) {
      if (this.storageGet('bkup_refresh_token') && this.storageGet('bkup_refresh_token') == res.data['Refresh-Token']) {
        return;
      }
      // 更新された場合のみ保存
      if (res.data['Refresh-Token'] != null && this.storageGet('refresh_token') != res.data['Refresh-Token']) {
        this.storageSave(JSON.stringify({
          'access_token': res.data['Access-Token'],
          'refresh_token': res.data['Refresh-Token'],
          'bkup_refresh_token': this.storageGet('refresh_token')
        }))
      }
    },
    /**
     * 決済トークン更新
     * @return {[type]}
     */
    async settlementTokenUpdate() {
      this.init = this.storageGet('*');
      if (!this.init.login_flg) {
        return true
      }

      var settlementParams = {
        'sid': this.init.sid,
        'service_cd': this.init.service_cd,
        'refresh_token': this.init.refresh_token,
      };
      var update = await this.apiCall('update-settlement-token', settlementParams, 'post', 2);
      if (!update || !update.refresh_token) {
        return false
      } else {
        this.storageSave(JSON.stringify({
          'refresh_token': update.refresh_token,
          'settlement_token': update.settlement_token,
          'bkup_refresh_token': this.storageGet('refresh_token')
        }));
      }

      return true
    },
    /**
     * アクセストークンなど、ユーザ関連の情報をクリアする
     */
    clearUserAndToken() {
      this.storageSave(JSON.stringify({
        'user_id'   : 0,
        'user_name' : 'ゲスト',
        'nickname' : 'ゲスト',
        'device_uid': 'cleared',
        'login_id'  : 'cleared',
        'access_token'  : 'cleared',
        'refresh_token'  : 'cleared',
      }));

      // Nativeに伝える
      this.requestUpdateUserToNative()
    },
    /**
     * やまやID以外をクリアする（ECに渡す必要があるため）
     */
    clearUserAndTokenExcludingDeviceUid() {
      this.storageSave(JSON.stringify({
        'user_id'   : 0,
        'user_name' : 'ゲスト',
        'nickname' : 'ゲスト',
        'login_id'  : 'cleared',
        'access_token'  : 'cleared',
        'refresh_token'  : 'cleared',
      }));

      // Nativeに伝える
      this.requestUpdateUserToNative()
    },
    /**
     * NativeにLoginページを表示するよう要求する
     */
    requestToNativeToShowLogin(){
      const login_error_alert_req = {
        status: 701,
        destination: 'https://' + process.env.VUE_APP_HOST_NAME + "/sp/login/index",
        transition_type: 2
      }
      this.screenTransition(login_error_alert_req)
    },
    /**
    /**
     * Nativeにサブメニューを表示するよう要求する
     */
    requestToNativeToShowSubmenu(){
      const req = {
        transition_type: 3,
        feature_id: 'sub_menu',
        screen_id: 'sub_menu',
        destination: 'sub_menu', // これを入れないとiOS側が期待の分岐に入らない。値があればなんでもいい。
      }
      this.screenTransition(req)
    },

    requestToNativeToShowTopPageAfterNewAccountOrLogin(loginAction, yamayaId) {
      //ネイティブに戻す
      const tonative_req = {
        'feature_id': 'top',
        'screen_id': 'top-a',
        'transition_type': 2,
        'login_action': loginAction,
        'yamaya_id': yamayaId,
      };
      this.screenTransition(tonative_req)
    },

    /**
     * NativeにTopページを表示するよう要求する
     */
    async requestToNativeToShowTopPage() {
      const to_native_req = await this.getRequestToNativeToShowTopPage()
      if (!(this.screenTransition(to_native_req))) {
        this.callDialog('遷移エラー', '遷移情報が不正です。再度アクセスして下さい。', 2);
      }
    },

    async getRequestToNativeToShowTopPage() {
      const init = this.storageGet('*');

      /* ルーティングAPI実行 */
      const routing_req = {
        params: {
          'sid'       : init.sid,
          'device_uid': init.device_uid,
          'key'       : init.key,
          'os'        : init.os,
          'feature_id': 'top',
          'service_cd': init.service_cd,
        }};
      const routing_res = await this.apiCall('/user/routing', routing_req, 'get');
      if (!routing_res) {
        throw 'Err';
      }

      return {
        'Access-Token': init.access_token,
        'Refresh-Token': init.refresh_token,
        'user_id': init.user_id,
        'user_name': init.nickname, // nativeにはニックネームを返す
        'device_uid': init.device_uid,
        'destination': routing_res.destination,
        'feature_id': routing_res.feature_id,
        'screen_id': routing_res.screen_id,
        'transition_type': routing_res.transition_type,
      }
    },

    async getRequestToNativeToShowTopPageAfterDeleteAccount(nickname, device_uid) {
      const init = this.storageGet('*');

      /* ルーティングAPI実行 */
      const routing_req = {
        params: {
          'sid'       : init.sid,
          'device_uid': init.device_uid,
          'key'       : init.key,
          'os'        : init.os,
          'feature_id': 'top',
          'service_cd': init.service_cd,
        }};
      const routing_res = await this.apiCall('/user/routing', routing_req, 'get');
      if (!routing_res) {
        throw 'Err';
      }

      return {
        '_vue_param': true,
        'Access-Token': 'cleared',
        'Refresh-Token': 'cleared',
        'user_id': 0,
        'user_name': nickname,
        'device_uid': device_uid,
        'destination': routing_res.destination,
        'feature_id': routing_res.feature_id,
        'screen_id': routing_res.screen_id,
        'transition_type': routing_res.transition_type,
      }
    },

    // App.vueから必要部分を抜粋
    async initAfterNewAccountOrLogin() {
      const init_req = {
        params: {
          'sid': this.storageGet('sid'),
          'key': this.storageGet('key'),
          'device_uid': this.storageGet('device_uid'),
          'os': this.storageGet('os'),
          'app_version': this.storageGet('app_version'),
          'registration_id': this.storageGet('registration_id')
        }
      }
      init_req.params.device_uid = this.storageGet('device_uid')

      const init_res = await this.apiCall('/user/init', init_req);
      if (!init_res) return false;
      this.$set(this, 'init', init_res);

      // 不要な要素を削除し、初期起動APIの結果をlocalStorageに保存
      delete init_res.available_flgs;
      //delete init_res.menu_data;
      delete init_res.status;
      delete init_res.message;
      delete init_res['Access-Token'];
      delete init_res['Refresh-Token'];
      delete init_res['Settlement-Token'];
      this.storageSave(JSON.stringify(init_res));
    },

    /**
     * Nativeに店舗検索ページを表示するよう要求する
     */
    async requestToNativeToShowShopListPage() {
      const init = this.storageGet('*');

      /* ルーティングAPI実行 */
      const routing_req = {
        params: {
          'sid'       : init.sid,
          'device_uid': init.device_uid,
          'key'       : init.key,
          'os'        : init.os,
          'feature_id': 'info',
          'service_cd': init.service_cd,
        }};
      const routing_res = await this.apiCall('/user/routing', routing_req, 'get');
      if (!routing_res) {
        throw 'Err';
      }

      //ネイティブに戻す
      const tonative_req = {
        // TODO おそらくいらない
        // 'Access-Token'    : init.access_token,
        // 'Refresh-Token'   : init.refresh_token,
        // 'user_id'         : init.user_id,
        // 'user_name'       : init.nickname, // nativeにはニックネームを返す
        // 'device_uid'      : init.device_uid,
        'destination'     : routing_res.destination,
        'feature_id'      : routing_res.feature_id,
        'screen_id'       : routing_res.screen_id,
        'transition_type' : routing_res.transition_type,
      };
      if (!(this.screenTransition(tonative_req))) {
        this.callDialog('遷移エラー', '遷移情報が不正です。再度アクセスして下さい。', 2);
      }
    },
    removeTargetBlank(obj) {
      if(typeof (obj) === "string" || obj instanceof String) {
        return obj.replace(/target="_blank"/g,'')
      } else {
        return '';
      }
    },
    autoHyperLink(obj) {
      if(typeof (obj) === "string" || obj instanceof String) {
        return obj.replace(/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig,"<a href='$1' title='$1'>$1</a>");
      } else {
        return '';
      }
    },
  },
}
